<template>
	<div id="chart">
		<apexchart type="line" :height="350" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	name: "VueApexCharts",

	components: {
		apexchart: VueApexCharts,
	},

	props: {
		series: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			chartOptions: {
				chart: {
					// height: 350,
					type: "line",
					dropShadow: {
						enabled: false,
						color: "#000",
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2,
					},
					toolbar: {
						show: false,
					},
				},
				// colors: this.colors(),
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
					width: 2,
				},
				title: {
					text: this.$t("PrEPStatusForm.NewPrEPs"),
					align: "left",
					floating: false,
					style: {
						fontFamily: "Roboto",
					},
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					],
					title: {
						text: "",
					},
				},
				yaxis: {
					title: {
						text: "",
					},
				},
				legend: {
					position: "bottom",
					horizontalAlign: "center",
					floating: false,
					offsetY: 8,
					offsetX: 0,
				},
			},
		}
	},

	computed: {},

	beforeDestroy() {},

	methods: {
		colors() {
			let colors = [],
				colorLength = this.series.length || 10
			for (let i = 0; i < colorLength; i++) {
				colors.push(this.getRandomColor())
			}
			return colors
		},

		getRandomColor() {
			return "#" + Math.floor(Math.random() * 16777215).toString(16)
		},
	},
}
</script>

<style lang="scss">
.apexcharts-legend.position-bottom {
	flex-wrap: wrap;
	gap: 5px 10px;
}
</style>
